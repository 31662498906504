<template>
    <div class="banList">

        <div class="flexible-block ">
            <div class="c-row-actions-container" style="margin-top: -20px">
                <div class="c-row-actions">
                    <ul>
                        <li :class="{'active_tab': banList_tabs.activePuns}"
                            @click="getBanList(currentPage, {active: '1'})
                            changeFrameCustom('activePuns', banList_tabs)">Активные наказания</li>
                        <li :class="{'active_tab': banList_tabs.allPuns}"
                            @click="getBanList(currentPage, {})
                            changeFrameCustom('allPuns', banList_tabs)">Все наказания</li>
                        <li :class="{'active_tab': banList_tabs.filtered}"
                            @click="getBanList(currentPage, filters)
                            changeFrameCustom('filtered', banList_tabs)">Все фильтры</li>
                    </ul>
                </div>
            </div>
            <div class="pretty-border"/>
            <div v-show="banList_tabs.filtered" style="display: flex;">
                <CustomSelect style="margin: 2px; height: 30px" v-bind:options="getOptions(active_values)" default="Все" @send="changeFilter('active', active_values, $event)"/>
                <CustomSelect style="margin: 2px;" v-bind:options="getOptions(type_values)" default="Все" @send="changeFilter('type', type_values, $event)"/>
                <CustomSelect style="margin: 2px;" v-bind:options="getServers()" default="Все" @send="changeFilter('server', getServersMapping(), $event)"/>
                <input class="filter-input" type="text" v-model="filters.moderator" placeholder="Модератор">
                <input class="filter-input" type="text" v-model="filters.player" placeholder="Игрок">
                <input class="filter-input" type="button" value="Применить" @click="getBanList(currentPage, filters)" style="cursor: pointer">
            </div>

        </div>

        <div class="apreloader" v-show="!bList"/>
        <div class="flexible-block" v-show="bList">


            <table class="tb" style="margin-top: 10px">
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                </colgroup>

                <thead
                    style="margin-bottom: 10px;border-top: 1px solid rgba(128, 128, 128, 0.29);">
                <tr>
                    <th class="info-table-th">Дата</th>
                    <th class="info-table-th">Наказание</th>
                    <th class="info-table-th">Сервер</th>
                    <th class="info-table-th">Игрок</th>
                    <th class="info-table-th">Модератор</th>
                    <th class="info-table-th">Срок действия</th>
                    <th class="info-table-th">Причина</th>
                </tr>
                </thead>

                <tbody style="margin-top: 10px;">
                <tr v-for="operation in bList">
                    <td class="info-table-td">
                        {{ datetimeNormalizer(operation.created_at) }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.type.toUpperCase() }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.server_name }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.player }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.moderator }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.expire === 0 ? 'Вечно' : datetimeNormalizer(operation.expire_at) }}
                    </td>
                    <td class="info-table-td">
                        {{ operation.reason }}
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                class="info-table-paginator"
                v-if="pagesCount !== null && pagesCount !== 1">
                <ul>
                    <li v-for="pageNumber in pagesCount"
                        v-if="Math.abs(pageNumber - currentPage) < 3 || pageNumber === pagesCount || pageNumber === 1">
                        <span @click="setPage(pageNumber)"
                              :class="{current: currentPage === pageNumber,
                            last: (pageNumber === pagesCount && Math.abs(pageNumber - currentPage) > 3),
                             first:(pageNumber === 1 && Math.abs(pageNumber - currentPage) > 3)}">{{
                                pageNumber
                            }}</span>
                    </li>
                </ul>
            </div>
        </div>


    </div>

</template>

<script>
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";
import CustomSelect from "@/components/elements/donat/CustomSelect";

export default {
    name: "banList",
    mixins: [TimeFormatterMixin, PreloaderMixin, ChangeTabMixin],
    components: {CustomSelect},

    data() {
        return {
            bList: null,
            currentPage: 1,
            pagesCount: null,

            filters: {
                server_name: '',
                active: '',
                player: '',
                moderator: '',
                type: ''
            },

            active_values: {
                'Все': '',
                'Активные': '1',
                'Неактивные': '0'
            },

            type_values: {
                'Все': '',
                'Бан': 'ban',
                'Мут': 'mute',
                'Тюрьма': 'jail',
                'Кик': 'kick'
            },

            banList_tabs: {
                activePuns: true,
                allPuns: false,
                filtered: false,
            },

            tableComponentKey: 1
        }
    },

    methods: {
        getOptions(options) {
            let res = []
            for(var key in options)
                res.push(key)
            return res
        },

        changeFilter(key, mapping, res) {
            if(mapping !== null)
                res = mapping[res]
            this.filters[key] = res
            // this.getBanList(this.currentPage, this.filters)
        },

        getServers() {
            let res = ['Все']
            for(var key in this.$root.getPlayerData().luckperms)
                res.push(key)
            return res
        },

        getServersMapping() {
            let res = {'Все': ''}
            for(var key in this.$root.getPlayerData().luckperms)
                res[key] = key
            return res
        },

        setPage(pageNumber) {
            this.currentPage = pageNumber
            let filters = this.filters
            if (this.banList_tabs.activePuns){
                filters = {active: '1'}
            }else if (this.banList_tabs.allPuns){
                filters = {}
            }
            this.getBanList(this.currentPage, filters)
        },

        getBanList(pageNumber, filters) {
            this.bList = null

            this.preloaderStartLk('.apreloader')

            let url = `/api/pun?page=${pageNumber}&all=1`
            for (var key in filters){
                if(filters[key] !== '')
                    url = `${url}&${key}=${filters[key]}`
            }
            require('axios').get(url).then(data => {
                    this.bList = data.data.data
                    this.pagesCount = data.data.last_page
                    this.preloaderStop('.apreloader')
                }
            ).catch(err => {
                console.log(err)
            })
        },
    },

    mounted() {
        this.getBanList(1, {active: '1'})
    }

}
</script>
<style scoped lang="scss">
@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.filter-input {
    height: 35px;
    margin: 2px;
    background-color: #28272b;
    border-left: 5px solid #4f48b5;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 6px;
    color: #fff;
    padding-left: 1em;
    padding-right: 1em
}

.banList {
    @include flexible-column();
    @include fill-all-block();

    @include in-page-preloader();

    @include info-table-with-paginator();


    .pretty-border {
        @include smooth-border();
        margin: 0;
    }

    .flexible-block {
        width: 100%;
        @include pretty-actions();
    }
}


</style>